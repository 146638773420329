<template>
  <v-app-bar :elevation="2">


      <div style="margin-top: 40px">
        <div class="d-flex justify-space-between mb-12 ">




          <div
            class="ma-6 pa-1  align-self-center"
          >
            <router-link to="/">

              <img class="logo-menu align-self-center" src="https://letsgreenwave.com/storage/channel/1/1UFQuIaiYPz2JxDdbzEPZoRdYUkbILY4x03KJnVo.png" alt="">
            </router-link>
          </div>


          <div
            class="ma-1 pa-1 align-self-center"
          >

            <v-btn @click="locationpress" value="mdi-map-marker-outline">
              <v-icon size="30px">mdi-map-marker-outline</v-icon>
            </v-btn>
           <p>{{ pincode }}</p>

          </div>

          <div
            class="ma-1 pa-1 align-self-center"
          >

            <v-btn

              block
              color="black"
              size="100"
              prepend-icon="mdi-phone"

              href="tel:+91 81015 59957 "
            >

            </v-btn>

          </div>




        </div>

      </div>



  </v-app-bar>
</template>


<script setup lang="ts">
import { useTheme } from 'vuetify'

const theme = useTheme()

function toggleTheme () {
  theme.global.defaultTheme = theme.global.current.dark ? 'light' : 'dark'
}
</script>

<script  lang="ts">
import { store } from '../stores/store'

export default {

  data() {
    return {
      pincode: 734000,
      drawer: true,


    }
  },


  methods:{

    locationpress() {
      store.locationmodeal = true
    },
    pincodess () {
      this.pincode = store.pincode
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    }
  },
  computed: {

  },

  created() {
    this.pincodess()
  },

  watch: {
    pincode: function (val, oldVal) {
      console.log('inputVal changed');
      console.log(oldVal);
      console.log(val);
    },
  },


}

</script>

<style scoped lang="sass">
.logo-menu
  width: 150px
  margin-top: 10px

</style>
