<template>
  <div class="text-center ">

    <v-dialog
      fullscreen
      v-model="store.locationmodeal"
      width="100%"
    >
      <v-card
        max-width="400"
        prepend-icon="mdi-map-marker-outline"
        title="Update Pincode "
      >

        <div style="padding: 30px">



          <v-form fast-fail @submit.prevent>

          <v-text-field
            type="number"
            v-model="pincode"
            :rules="[rules.required, rules.counter]"
            label="Enter Pincode">


          </v-text-field>


            <div class="d-flex flex-wrap">


              <div class="flex-1-0 ma-1 pa-1">
                <v-btn
                  block
                  color="warning"
                  prepend-icon="mdi-check-decagram"
                  variant="tonal"
                  :loading="pinloading"
                  type="submit"
                  @click="pincodeButtonPressed"

                >
                  VERIFY MY PINCODE
                </v-btn>
              </div>

            </div>

          </v-form>


          <div class="d-flex flex-wrap">


            <div class="flex-1-0 ma-1 pa-1">
              <v-btn
                block
                color="success"
                prepend-icon="mdi-map-marker-radius-outline"
                variant="tonal"
                :loading="locloading"
                @click="locatorButtonPressed"
              >
                GET MY LOCATION
              </v-btn>
            </div>


          </div>


        </div>

        <div>


          <div class="d-flex justify-center ma-1 pa-1"  v-if="pincodeverified === true">
            <v-btn

              block
              color="info"
              prepend-icon="mdi-truck-delivery-outline"
              variant="tonal"
              @click="confirmvelloc"
            >
              Confirm My Location
            </v-btn>

          </div>


          <div

            style="border-right: 2px solid white;"
            class="ma-2 pa-2"
          >
            <b>HELP & SUPPORT</b>
          </div>

          <v-row style="padding-bottom: 5px">

            <v-col

              cols="6"
              sm="6"
            >
              <div class="d-flex justify-center " >
                <v-btn

                  block
                  color="info"
                  prepend-icon="mdi-phone"
                  variant="tonal"
                href="tel:+91 81015 59957 "
                >
                  +918101559957
                </v-btn>

              </div>

            </v-col>


            <v-col

              cols="6"
              sm="6"
            >
              <div class="d-flex justify-center " >
                <v-btn

                  block
                  color="info"
                  variant="tonal"
                  prepend-icon="mdi-whatsapp"
                href="https://wa.me/918101559957"
                >
                  +918101559957
                </v-btn>

              </div>

            </v-col>

          </v-row>





          <div class="d-flex justify-center  bg-surface-variant">
            <div

              style="border-right: 2px solid white;"
              class="ma-2 pa-2"
            >
              Delivery Pincode
            </div>
            <div

              class="ma-2 pa-2"
            >
              {{ pincode }}
            </div>
          </div>





          <div class="d-flex justify-center"  v-if="pincodeverified !== true">
            <v-sheet  width="95%">

              <p class="text-subtitle-1 text-center ma-1 pa-1" style="text-transform: uppercase">
                Cities are <span class="text-capitalize"> <b>OPERATIONAL</b></span>
                with <b>LOCAL DISTRIBUTION </b>
                from our  <b>LOCAL HUB</b>
              </p>


              <v-row >
                <v-col

                  v-for="opcit in opcity"
                  cols="4"
                  sm="4"
                >

              <v-chip
                variant="elevated"
                color="success"
                prepend-icon="mdi-office-building-marker-outline">
                {{opcit.city}}
              </v-chip>

                </v-col>

              </v-row>


            </v-sheet>

          </div>


          <div class="d-flex justify-center "
               style="margin-top: 20px; margin-bottom: 20px"
               v-if="pincodeverified !== true">
            <v-sheet  width="95%">

              <p class="text-subtitle-1 text-center ma-1 pa-1" style="text-transform: uppercase">
            Upcoming Cities with <b>LOCAL DISTRIBUTION</b>  with own <b>HUB</b>
              </p>


              <v-row >
                <v-col

                  v-for="opcit in upcity"
                  cols="4"
                  sm="4"
                >

              <v-chip  prepend-icon="mdi-map-marker-plus-outline">
                {{opcit.city}}
              </v-chip>

                </v-col>

              </v-row>


            </v-sheet>

          </div>




          <div class="d-flex justify-center mb-12 pa-1 ">




            <v-container class="bg-surface-variant">
              <v-row >
                <v-col
                  v-for="item in deliveryarea"
                  :key="item"
                  cols="6"
                  sm="6"
                >

                  <v-card
                    @click="confirmvelloc"

                          :subtitle=" item.area"

                  >
                    <template v-slot:title>
                      <span class="font-weight-black">{{ item.day  }}</span>
                    </template>

                    <v-card-text class="bg-surface-light pt-4">

                     We will deliver on {{finddelslot(item.delivery_day)}} in <b>{{item.area+', '+item.city}}</b>

                    </v-card-text>


                  </v-card>




                </v-col>
              </v-row>
            </v-container>










          </div>





        </div>

      </v-card>







    </v-dialog>
  </div>
</template>

<script>
import {store} from '../stores/store'
import location from '../stores/location'
import axios from "axios";
import URLLIST from "../stores/url";
import moment from "moment";
import products from "../stores/products";
import {supabase} from "@/stores/supabase";

export default {
  computed: {
    store() {
      return store
    }
  },

  data() {
    return {
      dialog: false,
      locloading: false,
      pinloading: false,
      address: null,
      delivery_day: null,
      day: null,
      pincode: null,
      lat: null,
      long: null,
      deliveryarea: [],
      pincodeverified: false,
      cartremoced: false,
        upcity: [],
        opcity: [],
      modal: store.locationmodeal,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 6 || 'Enter 6 Digit Pincode',
      },
    }
  },

  watch:{

  },

  methods: {

    confirmvelloc(){

       store.locationmodeal = false
        this.modal = false

    },

   async getcitylist() {

     try {
       //let opcitylist = await axios.get(URLLIST.OPERATIONAL_CITY);

       let opcitylist = await  supabase
         .from('operational_city').select('*')
       this.opcity = opcitylist.data;
     } catch (e) {
       console.log(e)

     }

    },

  async  getcitylistup() {

      try {
      //  let UPcitylist = await axios.get(URLLIST.UPCOMING_CITY);

        let UPcitylist = await  supabase
          .from('upcoming_city').select('*')

        this.upcity = UPcitylist.data;
      } catch (e) {
        console.log(e)

      }


    },


    finddelslot(day){
      const dayINeed = day-0; // for Thursday
      const today = moment().isoWeekday();

// if we haven't yet passed the day of the week that I need:
      if (today <= dayINeed) {
        // then just give me this week's instance of that day
        return moment().isoWeekday(dayINeed);
      } else {
        // otherwise, give me *next week's* instance of that same day
        return moment().add(1, 'weeks').isoWeekday(dayINeed).format("dddd, MMMM Do YYYY");
      }
    },

    async pincodeButtonPressed() {

      if (this.pincode !== null && this.pincode.length === 6 ) {
        this.pinloading = true
        await this.GetPincodeInfo(this.pincode);
        this.pinloading = false

      }  else {
        alert('Enter valid pincode');
      }



    },



    async locatorButtonPressed() {
      this.locloading = true
      navigator.geolocation.getCurrentPosition(
        position => {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          let kiom = this.GetLocationpincode(position.coords.latitude, position.coords.longitude);
        },
        error => {
          console.log(error.message);
        },
      )
    },

    async GetLocationpincode(latitude, longitude) {
      console.log(latitude, longitude)
      this.locloading = true
      try {
        let url7 = '?lat=' + latitude + '&lon=' + longitude + '&format=json'
        let pincode = await axios.get('https://nominatim.openstreetmap.org/reverse' + url7);
        let addressf = await pincode.data;
        this.address = addressf.display_name;
        location.state.address = addressf.display_name;
        this.pincode = addressf.address.postcode;
        location.state.pincode = addressf.address.postcode;

        await this.GetPincodeInfo(addressf.address.postcode)

        console.log(addressf);
      } catch (error) {
        console.log(error);
      }

      this.locloading = false
    },

    async GetPincodeInfo(pincode) {
      this.deliveryarea = null;
      try {

      //  let getaddresspincode3 = await axios.post(URLLIST.FETCH_PPINCODE, {"pincode": pincode});

        let getaddresspincode = await  supabase
          .from('deliveryarea').select() .eq('pincode', pincode);
        console.log(getaddresspincode.data.length)

        if(getaddresspincode.data.length === undefined ){

        alert("We are coming soon to your area. Stay tuned");

        } else {

          let oldpin = Number(localStorage.getItem('pincode'));
          if ((pincode) !== (oldpin) ){
            console.log(pincode)
            console.log(oldpin)
            localStorage.removeItem('cart');
            this.cartremoced = true
          }


          this.deliveryarea = await getaddresspincode.data;
          this.pincodeverified = true;
          this.hub = this.deliveryarea[0].hub;
          location.delivery_day = this.deliveryarea[0].delivery_day;
          location.day = this.deliveryarea[0].day;
          location.deliveryarea = this.deliveryarea[0].area;
          products.state.hub = this.deliveryarea[0].hub
          products.state.deliveryarea = this.deliveryarea
          console.log(products.state.hub);

          localStorage.setItem('deliveryarea', JSON.stringify(this.deliveryarea));
          localStorage.setItem('hub', this.deliveryarea[0].hub);
          localStorage.setItem('pincode', this.pincode);
          localStorage.setItem('address', this.address);
          localStorage.setItem('delivery_day', this.deliveryarea[0].delivery_day);
          localStorage.setItem('day', this.deliveryarea[0].day);
         // store.locationmodeal = false
        //  this.modal = false


        }




      } catch (error) {
        console.log(error)
      }

    },

   async Deliveryonboot(){
    let pinc = localStorage.getItem('pincode')
      if (pinc !== null){
      this.pincode = pinc
      }


   }
  },
  created() {

    this.Deliveryonboot();
    this.getcitylist();
    this.getcitylistup();


  }


}
</script>

<style scoped lang="sass">
.logo-menu
  width: 150px
  margin-top: 10px

</style>
