<template>
  <v-container class="fill-height">
    <div class="d-flex flex-wrap align-self-center align-center">

      <div class="pa-1 align-self-center align-center" style="width: 100%" v-if="prodload">

        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-skeleton-loader
                class="mx-auto border"
                max-width="100%"
                type="card-avatar, actions"
              ></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="12">
              <v-skeleton-loader
                class="mx-auto border"
                max-width="100%"
                type="card-avatar, actions"
              ></v-skeleton-loader>
            </v-col>


            <v-col cols="12" md="12">
              <v-skeleton-loader
                class="mx-auto border"
                max-width="100%"
                type="card-avatar, actions"
              ></v-skeleton-loader>
            </v-col>


          </v-row>
        </v-container>


      </div>

      <div style="margin-bottom: 5px;">
        <p style="padding-left: 10px" class="text-h6">Filter by SIZE</p>

        <v-container class="">
          <v-row no-gutters>
            <v-col
              v-for="n in sizes"
              :key="n"
              cols="6"
              sm="6"
            >
              <v-sheet class="ma-1 ">
                <v-btn @click="this.onsizepress(n.name)" color="brown-darken-4" variant="outlined">{{n.name}} Inch</v-btn>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>



      </div>



      <div
        v-for="product in products.state.productscat"
        :key="product"
        class=" pa-1 align-self-center align-center"
      >




        <Product :productdata="product"/>

      </div>
    </div>



  </v-container>
</template>

<script >
import { store } from '../stores/store'
import products  from '../stores/products';
import { useRoute } from 'vue-router'
import axios from "axios";
import URLLIST from "@/stores/url";
import {supabase} from "@/stores/supabase";



export default {

  data() {
    return {
      category: [],
      parma:null,
      sizes: [],
      caterload : false
    }
  },

  methods: {

   async onsizepress (size){
     const route = useRoute()

     let slug = this.slug;
     let data = {
       size : size,
       slug : slug
     }
     products.commit("productfetchcatattr",data);
    },

    async getsizes() {

      const route = useRoute()

      let slug = route.params.slug;
      this.slug = slug

      this.caterload = true

      try {

        let getaddresspincode = await  supabase
          .from('attributes').select()
          .match({
            "category":slug,
            type: 'size'
          })


       /* let getaddresspincode = await axios.post(URLLIST.ATTR_GET, {
          "category":slug,
          "type": "size"
        });*/

        console.log(getaddresspincode.data);
        this.sizes = getaddresspincode.data;


      } catch (error){
        console.log(error)
      }
      this.caterload = false
    },



    async checklocation() {
      let pincode =  localStorage.getItem('pincode')
      console.log(pincode)
      return pincode

    },

    async Setlocalitemstostate(){
      store.pincode =  localStorage.getItem('pincode');
      store.hub =  localStorage.getItem('hub');
      store.deliveryarea =  JSON.parse(localStorage.getItem('deliveryarea'));
      await this.GetProducts(store.hub);

    },

    async GetProducts(hub){

    },



    async setPincode() {
      if (await this.checklocation() === null){

        store.locationmodeal = true

      } else {

        store.locationmodeal = false
        await this.Setlocalitemstostate()
      }
    },

    async getprod() {
      const route = useRoute()

      let slug = route.params.slug;
      products.commit("productfetchcat",slug);


    }
  },

  watch:{

    checkpinnnnn(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    },
    checkhub(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
      products.commit("productfetchcat","CARRY BAGS");
    }

  },

  computed: {
    products() {
      return products
    },

    prodload() {
      return products.state.prodload
    },
    checkpinnnnn () {
      return store.pincode
    },
    checkhub () {
      return products.state.hub
    }
  },

  setup() {

  },

  created() {



    this.setPincode();
    this.getprod();
    this.getsizes();


  },

  mutations: {

    update(state, product) {

    },
  },


}
</script>
