<template>
  <div class="text-center ">

    <v-dialog
      fullscreen
      v-model="authstatus"
      width="100%"
    >

      <v-card
        max-width="400"
        prepend-icon="mdi-account-star"
        title="Sign in using your info "
      >

        <v-container class="d-flex justify-center" >
          <v-row no-gutters>

            <v-col  cols="12"
                    sm="12">
              <div class="d-flex justify-center mb-12">
                <v-sheet class="ma-2 pa-2 justify-center align-self-center align-center">
                  <v-img
                    :width="208"
                    aspect-ratio="16/9"
                    cover
                    class=" justify-center align-self-center align-center"
                    src="@/assets/raw_horizontal_website.svg"
                  ></v-img>
                </v-sheet>
              </div>
            </v-col>

            <v-col

              cols="12"
              sm="12"

              v-if="loginp1"
            >
              <v-sheet class="ma-1 pa-3 d-flex justify-center"

                      >


              <MazPhoneNumberInput
                v-model="user_name"
                class="align-self-center align-center"
                v-model:country-code="countryCode"
                show-code-on-list
                orientation="row"
                :preferred-countries="['IN','BT','BD','NP']"
                :ignored-countries="['PK']"
                @update="results = $event"
              />

              </v-sheet>


              <v-sheet class="ma-2 pa-2">
                <v-btn :loading="loginp1loading" prepend-icon="mdi-login" @click="ReqOPp" color="success" block variant="outlined">
                  Continue Login
                </v-btn>



             <div class="ma-2 pa-2">

               <p class="text-subtitle-3 ma-2 pa-2 text-red-accent-4">
                 ** If you encounter any problems, please reach out to our support team through the following channels
               </p>

               <v-row style="padding-bottom: 5px">

                 <v-col

                   cols="6"
                   sm="6"
                 >
                   <div class="d-flex justify-center " >
                     <v-btn

                       block
                       color="pink"
                       prepend-icon="mdi-phone"
                       variant="tonal"
                       href="tel:+91 81015 59957 "
                     >
                       +918101559957
                     </v-btn>

                   </div>

                 </v-col>


                 <v-col

                   cols="6"
                   sm="6"
                 >
                   <div class="d-flex justify-center " >
                     <v-btn

                       block
                       color="success"
                       variant="tonal"
                       prepend-icon="mdi-whatsapp"
                       href="https://wa.me/918101559957"
                     >
                       +918101559957
                     </v-btn>

                   </div>

                 </v-col>

               </v-row>



             </div>

              </v-sheet>
            </v-col>







<!--        two    -->


            <v-col
              cols="12"
              sm="12"

              v-if="loginp2"
            >
              <v-sheet class="ma-2 pa-2">

                <v-alert
                  closable
                  icon="mdi-lock-reset"
                  title="OTP SENT"
                  text="OTP has been successfully sent to your registered mobile number via WhatsApp and Email"
                  type="success"
                ></v-alert>


              </v-sheet>


              <v-sheet class="ma-2 pa-2">
                <v-otp-input :length="4" v-model="enteredotp" placeholder="0"></v-otp-input>
              </v-sheet>

              <v-sheet class="ma-2 pa-2">


                <v-btn @click="verifyoitp" prepend-icon="mdi-login" :loading="loginp2loading" color="success" block variant="outlined">
                  Verify OTP
                </v-btn>

                <br>

                  <v-btn @click="goback" prepend-icon="mdi-arrow-left" color="info" block variant="outlined">
                  Go Back
                </v-btn>



              </v-sheet>
            </v-col>



            <!--        two    -->



            <!--        THREE    -->


            <v-col
              cols="12"
              sm="12"

              v-if="loginp3"
            >
              <v-sheet class="ma-2 pa-2">

                <v-alert
                  closable
                  icon="mdi-account-plus-outline"
                  title="REGISTER YOUR ACCOUNT"
                  text="We couldn't find your account. Please proceed by entering your basic info"
                  type="info"
                ></v-alert>


              </v-sheet>


              <v-sheet class="ma-2 pa-2">
                <v-form fast-fail @submit.prevent>
                  <v-text-field
                    v-model="name"
                    :rules="firstNameRules"
                    label="Name"
                  ></v-text-field>

                  <MazPhoneNumberInput
                    v-model="phone"
                    v-model:country-code="countryCode"
                    show-code-on-list
                    orientation="row"
                    :preferred-countries="['IN','BT','BD','NP']"
                    :ignored-countries="['PK']"
                    @update="results = $event"
                  />

                  <br>
                  <br>




                  <v-text-field
                    type="email"
                    v-model="email"
                    :rules="EmailRules"
                    label="Email Address"
                  ></v-text-field>


                  <v-btn prepend-icon="mdi-login"
                         :loading="loginp3loading"
                         @click="register"  type="submit" color="success" block variant="outlined">
                    Continue
                  </v-btn>
                </v-form>
              </v-sheet>

              <v-sheet class="ma-2 pa-2">

              </v-sheet>
            </v-col>


            <v-snackbar
              v-model="errorlogin"
              :timeout="2000"
              color="red"
              elevation="24"
            >
              {{ texterrlogin }}

              <template v-slot:actions>
                <v-btn
                  color="red"
                  variant="text"
                  @click="errorlogin = false"
                >
                  Close
                </v-btn>


                <br>

                <v-btn @click="goback" prepend-icon="mdi-arrow-left" color="info" block variant="outlined">
                  Go Back
                </v-btn>
              </template>
            </v-snackbar>
            <!--        THREE    -->




          </v-row>
        </v-container>

      </v-card>
    </v-dialog>
  </div>
</template>


<script  lang="ts">

import AuthStores  from '../stores/AuthStores';
import axios from "axios";
import URLLIST from "../stores/url";
import 'maz-ui/styles' // or import 'maz-ui/css/main.css'

export default {

  data() {
    return {
     loginp1: true,
     loginp1loading: false,
     loginp2loading: false,
     loginp3loading: false,
     loginp2: false,
     loginp3: false,
      user_name: null,
      enteredotp : null,
      enteredotp_id : null,
      texterrlogin: null,
      errorlogin: false,
      countryCode:'IN',
     name: null,
      email:null,
      phone: null,

    }
  },


  methods: {

    async register() {
      this.loginp3loading = true
      if (this.name == null || this.email == null || this.phone == null) {
        alert("Please enter a valid email , phone and name");
      } else {

        try {
          let profile = await axios.post(URLLIST.REGISTER_API, {
            email: this.email,
            phone: this.phone,
            name: this.name,
          });

          console.log(profile);

          if(profile.status === 403) {

            this.texterrlogin = "you email or phone is already registered"
            this.errorlogin = true

          } else {

            this.user_name = await this.phone;

           await console.log(this.user_name);
           await console.log(profile.data);

            this.loginp3loading = false
              this.loginp1 = true
              this.loginp2 = false
              this.loginp3 = false

            await this.ReqOPp();

          }


        } catch (e) {

          console.log(e)

          this.texterrlogin = "Please check the data & try again"
          this.errorlogin = true
          this.loginp3loading = false
        }

      }

    },

    goback(){
      this.loginp1 = true
      this.loginp2 = false
      this.loginp3 = false
    },

    async verifyoitp(){

      this.loginp2loading = true

      try {
        let getaddresspincode = await axios.post(URLLIST.OTP_API, {
          otp: this.enteredotp,
          otp_id: this.enteredotp_id
        });

        let gfdujyt = getaddresspincode.data;

        if (gfdujyt.status === "true"){

          localStorage.setItem('authkey', getaddresspincode.data.authkey);
          AuthStores.state.authkey = getaddresspincode.data.authkey


          try {
            let profile = await axios.post(URLLIST.PROFILE_API, {
              authkey: getaddresspincode.data.authkey
            });

            if ( profile.status === 401){

              this.texterrlogin = "You have entered invalid OTP. Please check your whatsapp oe email for correct otp"
              this.errorlogin = true


            } else {

              let profiledata = await profile.data




              AuthStores.state.name = await profiledata.name
              AuthStores.state.email = await profiledata.email
              AuthStores.state.phone = await profiledata.phone
              AuthStores.state.username = await profiledata.username

              localStorage.setItem('name',await profiledata.name);
              localStorage.setItem('email',await profiledata.email);
              localStorage.setItem('phone', await profiledata.phone);
              localStorage.setItem('username', await profiledata.username);
              console.log(profiledata)

              AuthStores.state.authstatus = false


            }



          } catch (e) {

          }




        } else {

          this.texterrlogin = "You have entered invalid OTP. Please check your whatsapp oe email for correct otp"
            this.errorlogin = true

        }




      } catch (error) {

      }

      this.loginp2loading = false




    },

    async ReqOPp(){

      this.loginp1loading = true;

      try {
        let getaddresspincode = await axios.post(URLLIST.LOGIN_API, {
          "email": this.user_name
        });

        let retdata = await getaddresspincode.data;



        if (retdata.message == true){

          console.log(getaddresspincode.data);

          this.loginp1 = false
            this.loginp1loading = false
            this.loginp2 = true
            this.enteredotp_id = retdata.otp_id


        } else {

          this.loginp1 = false
            this.loginp1loading = false
          this.loginp3 = true



        }







        this.loginp1loading = false;

      } catch (e) {

      }

    },




    async Checkiflogin(){


      let authkey = await localStorage.getItem('authkey')

      if (authkey !== null){
        try {
          let profile = await axios.post(URLLIST.PROFILE_API, {
            authkey: authkey
          });

          if ( profile.status === 401){

            this.texterrlogin = "You need to Login to continue"
            this.errorlogin = true


          } else {

            let profiledata = await profile.data




            AuthStores.state.name = await profiledata.name
            AuthStores.state.email = await profiledata.email
            AuthStores.state.phone = await profiledata.phone
            AuthStores.state.username = await profiledata.username

            localStorage.setItem('name',await profiledata.name);
            localStorage.setItem('email',await profiledata.email);
            localStorage.setItem('phone', await profiledata.phone);
            localStorage.setItem('username', await profiledata.username);
            console.log(profiledata)

            AuthStores.state.authstatus = false


          }



        } catch (e) {

        }

        return true


      } else {
        return false
      }




    },

  },




  created() {

    this.Checkiflogin();

  },



  watch:{

    authstatus(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    },


  },
  computed: {
    authstatus() {
      return AuthStores.state.authstatus
    }
  }
}
</script>
