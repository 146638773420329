/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'
import product from './stores/products';
import * as Sentry from "@sentry/vue";

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'
import Vue from "@vitejs/plugin-vue";

const app = createApp(App)

app.use(product)


registerPlugins(app)





Sentry.init({
  app,
  dsn: "https://010d0d16ea944293af4ab58c9f89b414@o4506793170698240.ingest.us.sentry.io/4507136947519488",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app')
