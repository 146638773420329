import { reactive } from 'vue'

export const store = reactive({
  cart: 0,
  cartitem:[],
  locationmodeal: true,
  deliveryarea:null,
  pincode:null,
  address:null,
  pincodeverified:false,
  hub:null,


  checklocalpincode() {
    this.pincode = localStorage.getItem('pincode');
  }

})
