import { createStore } from 'vuex'
import axios from "axios";
import URLLIST from "./url";

export default createStore({
  state: {
    authstatus: true,
    name : null,
    email : null,
    phone : null,
    city : null,
    address : null,
    username : null,
    authkey: null,
    lat :null,
    long :null



  },
  mutations: {
    async undertostate(state) {

      let authkey = localStorage.getItem('authkey')

      if (authkey !== null){
        try {
          let profile = await axios.post(URLLIST.PROFILE_API, {
            authkey: authkey
          });

          if ( profile.status === 401){



          } else {

            let profiledata = await profile.data


            state.name = await profiledata.name
            state.email = await profiledata.email
            state.phone = await profiledata.phone
            state.username = await profiledata.username
            state.address = await profiledata.address
            state.gstin = await profiledata.gstin
            state.city = await profiledata.city
            state.lat = await profiledata.lat
            state.long = await profiledata.long

            localStorage.setItem('name',await profiledata.name);
            localStorage.setItem('email',await profiledata.email);
            localStorage.setItem('phone', await profiledata.phone);
            localStorage.setItem('username', await profiledata.username);
            localStorage.setItem('address', await profiledata.address);
            localStorage.setItem('gstin', await profiledata.gstin);
            localStorage.setItem('city', await profiledata.city);
            localStorage.setItem('lat', await profiledata.city);
            localStorage.setItem('long', await profiledata.city);
            console.log('profiledata')

            state.authstatus = false


          }



        } catch (e) {

        }




      } else {
        return false
      }





    },


    async Checkiflogin(state){


      let authkey = localStorage.getItem('authkey')

      if (authkey !== null){
        try {
          let profile = await axios.post(URLLIST.PROFILE_API, {
            authkey: authkey
          });

          if ( profile.status === 401){

            this.texterrlogin = "You need to Login to continue"
            state.authstatus = true


          } else {

            let profiledata = await profile.data




            state.name = await profiledata.name
            state.email = await profiledata.email
            state.phone = await profiledata.phone
            state.username = await profiledata.username
            state.address = await profiledata.address
            state.gstin = await profiledata.gstin
            state.city = await profiledata.city
            state.authkey = authkey

            localStorage.setItem('name',await profiledata.name);
            localStorage.setItem('email',await profiledata.email);
            localStorage.setItem('phone', await profiledata.phone);
            localStorage.setItem('username', await profiledata.username);
            console.log(profiledata)

            state.authstatus = false


          }



        } catch (e) {

          console.log(e)

        }

        return true


      } else {
        return false
      }




    },

  },

  actions: {

  },
  getters: {

  }
})
