<template>


  <IncludesAll/>


  <v-sheet class=" justify-center" style="padding: 20px">



    <v-dialog
      fullscreen
      v-model="ordersuccess"
      width="100%"
    >
      <v-card
        max-width="400"
        prepend-icon="mdi-map-marker-outline"
        title="Your order has been successfully "
      >

        <div style="padding: 30px">



          <v-sheet class="d-flex flex-wrap justify-center ma-1 pa-1" >

            <div v-if="isselect">

              <v-timeline align="start" side="end">
                <v-timeline-item
                  dot-color="pink"
                  size="small"
                >
                  <div class="d-flex">
                    {{Formatdate(Date())}}
                    <div>
                      <strong>{{ select.area }}</strong>
                      <div class="text-caption mb-2">
                        Order Placed
                      </div>
                    </div>
                  </div>
                </v-timeline-item>

                <v-timeline-item
                  dot-color="teal-lighten-3"
                  size="small"
                >
                  <div class="d-flex">
                    8-10 AM {{finddelslot(select.delivery_day)}}
                    <div>
                      <strong>{{ select.hub }}</strong>
                      <div class="text-caption mb-2">
                        Order Preparation
                      </div>

                    </div>
                  </div>
                </v-timeline-item>

                <v-timeline-item
                  dot-color="green"
                  size="small"
                >
                  <div class="d-flex">
                    10 AM-8 PM {{finddelslot(select.delivery_day)}}
                    <div>
                      <strong>{{ select.area }}</strong>
                      <div class="text-caption mb-2">
                        Order Delivered
                      </div>
                    </div>
                  </div>
                </v-timeline-item>


              </v-timeline>

            </div>

          </v-sheet>


          <v-sheet class="d-flex flex-wrap justify-center ma-1 pa-1" >

            <p class="text-h6 ma-1 pa-1">Whats Next? Sit back relax! Your order will be delivered on the scheduled delivery date</p>

            <v-btn prepend-icon="mdi-home" to="/"  block color="green-darken-2">
              Go To Homepage
            </v-btn>

            <br>
            <br>

            <v-btn prepend-icon="mdi-package-variant-closed-plus" to="/"  block color="yellow-darken-2">
              Go To My Orders
            </v-btn>

          </v-sheet>


        </div>

      </v-card>


    </v-dialog>



  <v-form fast-fail @submit.prevent>
    <v-text-field
      v-model="userinfo.name"
      :rules="[rules.required]"
      label="Name"
    ></v-text-field>

    <v-text-field
      v-model="userinfo.gstin"
      label="GSTIN(Optional)"
    ></v-text-field>

    <v-text-field
      v-model="userinfo.phone"
      :counter="7"
      :rules="[rules.required]"
      label="Phone Number"
    ></v-text-field>

    <v-text-field
      v-model="userinfo.address"
      :rules="[rules.required]"
      label="Address"
    ></v-text-field>

    <v-text-field
      v-model="userinfo.city"
      :rules="[rules.required]"
      label="City"
    ></v-text-field>

    <v-select   v-model="select"
                :rules="[rules.required]"
                :item-props="itemProps"
                :items="deliveryarea"
                label="Select Area"></v-select>



    <div v-if="isselect">

        <v-timeline align="start" side="end">
          <v-timeline-item
            dot-color="pink"
            size="small"
          >
            <div class="d-flex">
              {{Formatdate(Date())}}
              <div>
                <strong>{{ select.area }}</strong>
                <div class="text-caption mb-2">
                  Order Placed
                </div>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item
            dot-color="teal-lighten-3"
            size="small"
          >
            <div class="d-flex">
             8-10 AM {{finddelslot(select.delivery_day)}}
              <div>
                <strong>{{ select.hub }}</strong>
                <div class="text-caption mb-2">
                  Order Preparation
                </div>

              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item
            dot-color="green"
            size="small"
          >
            <div class="d-flex">
              10 AM-8 PM {{finddelslot(select.delivery_day)}}
              <div>
                <strong>{{ select.area }}</strong>
                <div class="text-caption mb-2">
                  Order Delivered
                </div>
              </div>
            </div>
          </v-timeline-item>


        </v-timeline>

    </div>
    <v-sheet
      class="d-flex align-start justify-start flex-wrap text-center mx-auto px-4 mx-4"


      :elevation="23 "

      color="green-lighten-4"

      border
      max-width="800"
      width="100%"
      rounded
    >
      <p style="text-transform: uppercase; text-align: center" class="text-center"><b>!! {{products.state.offers[2].text}} !!</b></p>
      <p style="text-transform: uppercase" class="text-center">
        {{products.state.offers[2].terms}}

      </p>


    </v-sheet>


    <v-sheet
      class="d-flex align-start justify-start flex-wrap text-center mx-auto px-4"


      :elevation="subsciption ? `23` : `4` "

      :color="subsciption ? `teal-lighten-5` : `white-darken-3` "

      border
      max-width="800"
      width="100%"
      rounded
    >

      <div class=" text-left" style="padding-top: 25px; width: 100%">

        <p class="text-h5 text-center"> <b>Order Summery</b></p>

        <br>

        <div class="d-flex justify-center" style="width: 100%">
          <div class="ma-1 pa-1" style="width: 35%">
            <p class="">Subtotal </p> <br>
            <p class="">Discount </p> <br>
            <p class="">Amt Disc </p> <br>
            <p class="">GST @18%</p> <br>
            <p class="text-h5"><b>Total</b></p> <br>

         </div>
          <div class="ma-1 pa-1" style="width: 45%">
            <p> ₹  {{ amt }}</p> <br>
            <p> ₹  {{ disc }}</p> <br>
            <p> ₹  {{ damt }}</p> <br>
            <p> ₹  {{ tax }}</p> <br>
            <p class="text-h5"> ₹  {{ total }}</p>
          </div>
        </div>




      </div>


    </v-sheet>


    <br>

    <br>



      <v-sheet
      class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4"

      :color="subsciption ? `green-darken-3` : `white-darken-3` "
      :elevation="subsciption ? `4` : `23` "
      max-width="800"
      width="100%"
      rounded
    >


        <v-switch
          v-model="subsciption"
          label="Create Subscription for your order"
          hide-details
          inset
        ></v-switch>


    <div  class="pa-1 ma-1">

      <div v-if="subsciption">
        <v-radio-group inline v-model="subsciptionval"  label="Subscription Interval">
          <v-radio label="Weekly" value="7"></v-radio>
          <v-radio label="Bi Weekly" value="14"></v-radio>
          <v-radio label="Monthly" value="13"></v-radio>
        </v-radio-group>
      </div>



      <div>
        <p class="" v-if="subsciption === false">
          Your Items added in the cart will be automatically ordered on as  per the timeframe you select.
          You dont have to manually create order everytime.We will send you a remainder when your order is created via
          whatsapp & email.If you dont need the order you can delete it by logging in.
        </p>
      </div>



      <p v-if="subsciption === false" class="pa-2 ma-1 text-h6">
Subscribe to the products <b>Weekly</b> , <b>Bi-Weekly</b> or <b>Monthly</b> and get extra
        <b>5% discount</b>
        lifetime</p>


      <p v-if="subsciption" class="pa-2 ma-1 text-h6">
        <b> 5% Extra discount Applied</b>, This discount will be applied to every product in this
      Subscription list on every future delivery for this subscription list </p>



    </div>

    </v-sheet>



    <div class="pa-2 ma-1">
      <v-btn :loading="locloading" prepend-icon="mdi-map-marker-outline" to="checkout" block color="info" @click="locatorButtonPressed">
        Allow Location for easy delivery
      </v-btn>

      <br>

      {{address}}

      <p class="pa-2 ma-1">
        *Please allow us to locate your address for smoother delivery experiences and minimising unnecessary calls. Or you may skip it
      </p>

    </div>








    <v-btn
      class="me-4 pa-2 ma-1"
      type="submit"
      @click="CreateOeder"
      block
      :loading="orderloading"
      color="success"
      prepend-icon="mdi-truck-check-outline"
    >
      Submit Order
    </v-btn>





  </v-form>

  </v-sheet>


  <AppFooter />

</template>
<script >
import axios from "axios";
import AuthStores from "../stores/AuthStores";
import moment from "moment/moment";
import URLLIST from "../stores/url";
import lodash from 'lodash';
import products from "../stores/products";

export default {

  data() {
    return {
      dialog: false,
      locloading: false,
      orderloading: false,

      pinloading: false,
      address: null,
      name: null,
      gstin:null,
      phone: null,
      select: null,
      isselect: true,
      pincode: null,
      lat: null,
      long: null,
      deliveryarea: [],
      ordersuccess:false,
      subsciption:false,
      subsciptionval:null,
      serverdeldate: null,
      amt: 0,
      tax: 0,
      total: 0,
      disc: 0,
      damt: 0,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 6 || 'Enter 6 Digit Pincode',
      },

    }
  },

  created() {
    this.GetDeliveryArea();
    this.updateuserinfo();
    this.calculateTotal();
    AuthStores.commit('Checkiflogin');
  },
  methods:{

    calculateTotal(){

      let disc = this.disc;
      let cartitem = JSON.parse(localStorage.getItem('cart'));

      const result =  lodash.sumBy(cartitem, function(o)
      { return (o.rate * o.qty )});
    //  const weight =  lodash.sumBy(cartitem, function(o) { return o.qty * o.weight_per_unit; });


    let caldisc = (disc /100 * result).toFixed(2);
    this.disc = caldisc;

    let fnlamt = (result-caldisc).toFixed(2)

      let tax = (fnlamt * 0.18).toFixed(2);
      this.amt = fnlamt
      this.damt = fnlamt
      this.tax = tax
      this.total = (Number(fnlamt)+Number(tax)).toFixed(2)

      console.log("result")
      console.log(result);
    //  alert(result)


    },

    calculateservdel(date){
    let fdeld =   this.finddeliveryday(date);
    this.serverdeldate = fdeld;
    console.log(fdeld);
    return fdeld;
    },

    Formatdate(day){

      return moment(day).format("dddd, MMMM Do YYYY, h:mm:ss a")

    },

    finddelslot(day){
     // alert(day)
      const dayINeed = day-0; // for Thursday
      const today = moment().isoWeekday();

// if we haven't yet passed the day of the week that I need:
      if (today <= dayINeed) {
        // then just give me this week's instance of that day
        return moment().isoWeekday(dayINeed).format("dddd, MMMM Do YYYY");
      } else {
        // otherwise, give me *next week's* instance of that same day
        return moment().add(1, 'weeks').isoWeekday(dayINeed).format("dddd, MMMM Do YYYY");
      }
    },

    finddeliveryday(day){
      const dayINeed = day-0; // for Thursday
      const today = moment().isoWeekday();

// if we haven't yet passed the day of the week that I need:
      if (today <= dayINeed) {
        // then just give me this week's instance of that day
        return moment().isoWeekday(dayINeed).toISOString();;
      } else {
        // otherwise, give me *next week's* instance of that same day
        return moment().add(1, 'weeks').isoWeekday(dayINeed).toISOString();
      }
    },

    async updateuserinfo(){
      AuthStores.commit('undertostate');

     // console.log(AuthStores.state.lat)


    },


    async CreateOeder(){

      this.orderloading = true

      console.log( this.userinfo)

      if (this.userinfo.name === null ||
        this.userinfo.phone === null ||
        this.select === null
      ){
        alert('Please enter your Entered information')
      } else {

        if (this.subsciption === true) {

          if (this.subsciptionval === null){
            alert('Please enter subscription Interval')
          } else {
            let data = {
              name: this.userinfo.name,
              phone: this.userinfo.phone,
              authkey: this.userinfo.authkey,
              select: this.select,
              city: this.userinfo.city,
              address: this.userinfo.address,
              lat: this.lat,
              long: this.long,
              subsciption: this.subsciption,
              subsciptionval: this.subsciptionval,
              gstin: this.userinfo.gstin,
              area: this.select,
              pincode: this.userinfo.pincode,
              serverdeldate: this.serverdeldate,
            }


          await this.submitorder(data)

         //  cart.commit('CreateOrder', {data});
          }

        } else {
          let data = {
            name: this.userinfo.name,
            phone: this.userinfo.phone,
            authkey: this.userinfo.authkey,
            city: this.userinfo.city,
            select: this.select,
            address: this.userinfo.address,
            lat: this.userinfo.lat,
            long: this.userinfo.long,
            subsciption: this.subsciption,
            subsciptionval: this.subsciptionval,
            gstin: this.userinfo.gstin,
            area: this.select,
            pincode: this.userinfo.pincode,
            serverdeldate: this.serverdeldate,
          }
          await this.submitorder(data);

         // cart.commit('CreateOrder', {data});
        }




      }


    },


    async submitorder(data){



      let cartds =  await JSON.parse(localStorage.getItem('cart'));

      console.log(data);

      let createode = await axios.post(URLLIST.ORDER_API, {
        cart:cartds,
        info:data,
      });

     let orderstatusdata =await createode.data;

     if(orderstatusdata.status === 401) {
       alert('Some Error occurred while submitting the order')

     } else {
       this.ordersuccess = true
     }

      this.orderloading = false


    },

    itemProps (delv) {
      return {
        title: delv.area,
        subtitle: delv.city,
      }
    },

    GetDeliveryArea(){

    this.deliveryarea =  JSON.parse(localStorage.getItem('deliveryarea'));


    },

    async locatorButtonPressed() {
      this.locloading = true
      navigator.geolocation.getCurrentPosition(
        position => {
          this.userinfo.lat = position.coords.latitude;
          this.lat = position.coords.latitude;
          this.userinfo.long = position.coords.longitude;
          this.long = position.coords.longitude;
          let kiom = this.GetLocationpincode(position.coords.latitude, position.coords.longitude);
        },
        error => {
          console.log(error.message);
        },
      )
    },

    async GetLocationpincode(latitude, longitude) {
      console.log(latitude, longitude)
      this.locloading = true
      try {
        let url7 = '?lat=' + latitude + '&lon=' + longitude + '&format=json'
        let pincode = await axios.get('https://nominatim.openstreetmap.org/reverse' + url7);
        let addressf = await pincode.data;
        this.address = addressf.display_name;
        this.pincode = addressf.address.postcode;

       // await this.GetPincodeInfo(addressf.address.postcode)

        console.log(addressf);
      } catch (error) {
        console.log(error);
      }

      this.locloading = false
    },
  },

  computed: {
    products() {
      return products
    },
    userinfo() {
      return AuthStores.state
    }
  },


  watch:{

    subsciption(value){

      if (value === true) {

        this.disc = 5;
        this.calculateTotal()
      } else {

        this.disc = 0;
        this.calculateTotal()
      }

    },

    select(value) {
      console.log(value);
      this.isselect = true;

      this.calculateservdel(value.delivery_day);
  ///    value.delivery_day
    },
    userinfo(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    },

  },

}



</script>
