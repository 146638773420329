<template>

  <p class="text-h4 text-green-darken-4 font-weight-bold" >Category</p>
  <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-container fluid>


      <v-row dense>
        <v-col
          v-for="category in category"
          :key="category.name"
          :cols="6"
        >
          <v-card  @click="this.gOToProduct(category)">
            <v-img
              :src="category.image"
              class="align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
              cover
            >
              <v-card-title class="text-white" v-text="category.name"></v-card-title>
            </v-img>


          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import URLLIST from "@/stores/url";
import router from "@/router";
import products from "@/stores/products";
import {supabase} from "@/stores/supabase";

export default {
  data: () => ({
    category:[],
    cards: [
      { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 6 },
      { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
      { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
    ],
  }),

  methods: {

    async gOToProduct(category){
      //products.state.caturl = await category.slug
     // router.push({ path: '/productbycategory', props: { plan: 'private' } })

      await router.push({name: 'productbycategory', params: {slug: category.slug}})



    },

    async getcategory() {

      try {

        let getcat = await  supabase
          .from('category').select('*')

       // let getcat = await axios.get(URLLIST.CATEGORY);

        this.category = await getcat.data;

      } catch (e) {

        console.log(e)


      }

    },

  },

  created() {
    this.getcategory()
  }


}
</script>
