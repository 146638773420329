import {createStore} from "vuex";

export default createStore({
  state: {
    width: null,
    height: null,
    hide:false

  },

})
