import {createStore} from 'vuex'
import * as _ from 'lodash';
import axios from "axios";
import URLLIST from "./url";

export default createStore({
  state: {
    cart: [],
    hub:"GW_IND_DC_COB",
    pincode:null,

  },
  mutations: {

    async CreateOrder(state,payload){


      let cartds =  await JSON.parse(localStorage.getItem('cart'));
      console.log(cartds)

      let createode = await axios.post(URLLIST.ORDER_API, {
      cart:cartds,
        info:payload,
      });


    },

    async GetCartItemsLS(state){
      let cartds =  await JSON.parse(localStorage.getItem('cart'));
      state.cart = cartds;
      console.log(cartds)
      return state.cart;

    },

    countcart(state) {

    },

   async cartdelete(state,payload) {
      let cart = await JSON.parse(localStorage.getItem('cart'));
      console.log(cart);
      console.log(payload);
     var removed = _.remove(cart, item => item.idx === payload.ffff);

     this.state.cart = cart;
     localStorage.setItem('cart',JSON.stringify(cart));

     console.log(removed)
     console.log(cart)


    },

    cartincrement (state, payload) {
      let oldcart = JSON.parse(localStorage.getItem('cart'));
      let product = payload.payload;


      if (oldcart === null){

        let  productarray = {

          idx: Date.now(),
          code: product.productdata.code,
          name: product.productdata.name,
          gst: product.productdata.gst,
          image: product.productdata.image,
          rate: product.productdata.rate,
          size: product.productdata.size,
          fullfilment_center: product.productdata.fullfilment_center,
          qty: product.qty,
        };


        console.log(productarray);




        state.cart = [productarray];
       localStorage.setItem('cart', JSON.stringify([productarray]));

      } else {

        let  productarray = {

          idx: Date.now(),
          code: product.productdata.code,
          name: product.productdata.name,
          image: product.productdata.image,
          gst: product.productdata.gst,
          rate: product.productdata.rate,
          size: product.productdata.size,
          fullfilment_center: product.productdata.fullfilment_center,
          qty: product.qty,
        }


        let combileitems = _.concat(oldcart, productarray);
        localStorage.setItem('cart', JSON.stringify(combileitems));
        state.cart = combileitems;



      }

    }


  },

  actions: {
    updatecart (context) {
      context.commit('GetCartItemsLS');
    }
  },
  getters: {

  }
})
