import {createStore} from 'vuex'
import axios from "axios";
import URLLIST from "@/stores/url";
import products from "@/stores/products";
import {supabase} from "@/stores/supabase";

export default createStore({
  state: {
    locationmodeal: true,
    deliveryarea:null,
    pincode:null,
    address:null,
    pincodeverified:false,
    hub:null,
    delivery_day: null,
    day: null,
  },
  mutations: {


    async GetPincodestate(state) {
      let pincodeloc = localStorage.getItem('pincode');
      try {
       /* let getaddresspincode2 = await axios.post(URLLIST.FETCH_PPINCODE, {
          "pincode": pincodeloc
        });
*/
        let getaddresspincode = await  supabase
          .from('deliveryarea').select() .eq('pincode', pincodeloc);


        let delarea = await getaddresspincode.data

        console.log(getaddresspincode.data)

        if(getaddresspincode.data.length === undefined ){

          alert("We are coming soon to your area. Stay tuned");

        } else {
          state.hub = delarea[0].hub;
          state.delivery_day = delarea[0].delivery_day;
          state.day = delarea[0].day;
          state.pincode = delarea[0].pincode;
          state.deliveryarea = delarea[0].area;


          // store.locationmodeal = false
          //  this.modal = false


        }




      } catch (error) {
        console.log(error)
      }

    },


  }
})
