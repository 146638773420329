<template>
  <IncludesAll/>
  <v-sheet class="d-flex flex-wrap justify-center ma-1 pa-1" >

  <v-btn prepend-icon="mdi-truck-check-outline" to="checkout" block color="green-darken-2">
    Checkout Order
  </v-btn>

  </v-sheet>
  <CartItem v-for="cart in cart" :carts="cart" />

  <v-sheet class="d-flex flex-wrap justify-center ma-1 pa-1" >

    <v-btn prepend-icon="mdi-truck-check-outline" to="checkout"  block color="green-darken-2">
      Checkout Order
    </v-btn>

  </v-sheet>
  <AppFooter />
</template>

<script lang="ts" >

import AuthStores from "../stores/AuthStores";
import cart from "../stores/cart";

export default {



  data() {
    return {
      carts: []

    }
  },

  methods: {



   async getCartitems() {
     let CartItems = localStorage.getItem('cart');
     this.carts = await JSON.parse(CartItems);

    }

  },

  computed: {
    cart() {
      return cart.state.cart
    },
  },

  watch:{

    cart(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    }

  },



  created() {



 cart.dispatch('updatecart');
 AuthStores.commit('Checkiflogin');





  }



}




//
</script>
