<template>


  <v-sheet class="d-flex flex-wrap justify-center" border>

    <v-container class="">
      <v-row   align="center" c>
    <v-col
      cols="4"
      sm="4"
    >
      <v-sheet class="ma-1 pa-1">
        <v-img
          class="mx-auto"
          height="75"
          lazy-src="@/assets/images/placeholder_image.jpg"
          max-width="500"
          :src="carts.image"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="grey-lighten-4"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>
      </v-sheet>
    </v-col>

        <v-col

      cols="5"
      sm="5"
    >
      <v-sheet class="ma-1 pa-1" >
        {{ carts.name }}


      </v-sheet>
    </v-col>

        <v-col
      cols="2"
      sm="2"
    >
      <v-sheet class="ma-1 pa-1">
        <v-btn @click="deletecart"  color="red" icon="mdi-delete-outline"  variant="text">
          Delete
        </v-btn>
      </v-sheet>
    </v-col>

        <v-col
          cols="12"
          sm="12"
        >
          <v-sheet class="">
            {{ carts.qty }} Unit x ₹{{ carts.rate }} =
            ₹ {{ carts.qty * carts.rate }}
          </v-sheet>
        </v-col>

      </v-row>
    </v-container>


  </v-sheet>


</template>

<script>

import cart from "../stores/cart";
export default {
  props: ['carts'],

  data() {
    return {
      item: null

    }
  },

  setup(props) {
    // setup() receives props as the first argument.

  },
  methods:{
    async deletecart(){
      let ffff = this.carts.idx;

      console.log(ffff)

      cart.commit('cartdelete', {
        ffff
      })
    }
  },






}

</script>
