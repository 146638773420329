<template>
<div>

  <v-dialog
    fullscreen
    v-model="windowhide"
    width="100%"
  >

    <v-card
      max-width="400"
      prepend-icon="mdi-phone-rotate-portrait"
      title="Please use Phone Continue using Application   "
    >


      <v-container class="">
        <v-row no-gutters>
          <v-col


            cols="12"
          >
            <div class="d-flex justify-center mb-12">
              <v-sheet class="ma-2 pa-2 justify-center align-self-center align-center">
                <v-img
                  :width="208"
                  aspect-ratio="16/9"
                  cover
                  class=" justify-center align-self-center align-center"
                  src="@/assets/raw_horizontal_website.svg"
                ></v-img>
              </v-sheet>
            </div>
            <v-sheet class="ma-2 pa-2">

              <v-img
                :aspect-ratio="1"
                class="bg-white"
                src="@/assets/images/mobileonly.jpg"
                height="300"
              ></v-img>



            </v-sheet>
          </v-col>


          <v-col


            cols="12"
          >
            <v-sheet class="ma-2 pa-2" elevation="12" color="success">

             <p class="text-md-center text-center text-h4
">Please you a mobile device to experience our website.This is mobile only verson
             of the website</p>




            </v-sheet>
          </v-col>



        </v-row>
      </v-container>

    </v-card>
  </v-dialog>
</div>
</template>

<script>

import {useWindowSize} from "maz-ui";
import products from "@/stores/products";
import util from "@/stores/util";

export default {
  data() {
    return {
      hide : false
    }
  },


  methods:{
    async Windowsizecheck(){
      const { width, height } = useWindowSize();
      util.state.width = width;
      util.state.height = height;
      this.checkifmobile()

    },
    checkifmobile(){
      if(Number(this.windowwidth) > Number(this.windowheight)){
        console.log(util.state.hide)
        util.state.hide = true

      } else {
        util.state.hide = false
      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.Windowsizecheck);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  created() {

    this.Windowsizecheck()

  },

  watch:{

    windowwidth(value) {
    //  console.log(`My store value for 'doneTodosCount' changed to ${value}`);
      this.checkifmobile()
    },
    windowheight(value) {
     // console.log(`My store value for 'doneTodosCount' changed to ${value}`);

      this.checkifmobile()
    },

    windowhide(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    }

  },

  computed: {
    windowwidth() {
      return util.state.width
    },
    windowheight() {
      return util.state.height
    },

    windowhide() {
      return util.state.hide
    },


  },


}
</script>
