/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
//import { createWebHistory } from 'vue-router/auto'
import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import { setupLayouts } from 'virtual:generated-layouts'
import index from "../pages/index.vue";
import myorders from "../pages/myorders.vue";
import mysubs from "../pages/mysubs.vue";
import productbycategory from "../pages/productbycategory.vue";
import Checkout from "../pages/Checkout.vue";
import Cart from "../pages/Cart.vue";

const routes = [
  { path: '/', component: index ,   name: 'index'},
  { path: '/myorders', component: myorders,   name: 'myorders' },
  { path: '/mysubs', component: mysubs,   name: 'mysubs' },
  { path: '/productbycategory/:slug', component: productbycategory,   name: 'productbycategory' },
  { path: '/Checkout', component: Checkout,   name: 'Checkout' },
  { path: '/Cart', component: Cart,   name: 'Cart' },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  extendRoutes: setupLayouts,
})

export default router
