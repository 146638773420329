<template>

  <IncludesAll/>
<div>

   <div v-if="loading">
     <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>


   </div>

  <MyOrdersSubs style="margin-top: 10px" v-bind="item.length"  v-for="item in this.items" :items="item"/>

</div>
  <AppFooter />
</template>

<script lang="ts">

import axios from "axios";
import URLLIST from "../stores/url";
import AuthStores from "../stores/AuthStores";
import MyOrdersSubs from "../components/MyOrdersSubs.vue";

export default {
  components: {MyOrdersSubs},
  data() {
    return {
      items : [],
      loading: true,
    }
  },


  created() {
    AuthStores.commit('Checkiflogin');
      this.getordes();
  },

  methods:{
    async getordes(){

      this.loading = true
      let authkey = localStorage.getItem('authkey')

      try {
        console.log(this.userinfo)
        let createode = await axios.post(URLLIST.MY_ORDER_SUBS, {
          authkey:authkey,
        });

        this.items = createode.data;

        this.loading = false


        console.log(createode.data);
      } catch (error) {
        console.log(error)
        this.loading = false

      }

    }
  },

  computed: {
    userinfo() {
      return AuthStores.state
    }
  },


  watch:{

    userinfo(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    },

  },



}
</script>
