<template>
  <div class="footer-info">
  <v-bottom-navigation>

      <v-btn  to="/" value="recent">
        <v-icon>mdi-home</v-icon>

        <span>Home</span>
      </v-btn>

      <v-btn to="/myorders" value="favorites">
        <v-icon>mdi-package-variant-closed-plus</v-icon>

        <span>Orders</span>
      </v-btn>

    <v-btn to="/mysubs" value="favorites">
        <v-icon>mdi-reload</v-icon>

        <span>Subscription</span>
      </v-btn>

      <v-btn to="/Cart" value="nearby">
        <v-badge :content="cart.length"  color="error">

        <v-icon>mdi-cart</v-icon>

        </v-badge>

        <span>Added Products</span>
      </v-btn>


  </v-bottom-navigation>

  </div>

</template>

<script  lang="ts">
import cart from "../stores/cart";
import {supabase} from "../stores/supabase";

export default {
  data() {
    return {

    }
  },
  methods:{

  async  getproduts(){


    }



  },
  created() {
     this.getproduts();
  },
  computed: {
    cart() {
      return cart.state.cart
    },
  },

  watch:{

    cart(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    }

  },
}

</script>

<style scoped lang="sass">
  .social-link :deep(.v-icon)
    color: rgba(var(--v-theme-on-background), var(--v-disabled-opacity))
    text-decoration: none
    transition: .2s ease-in-out

    &:hover
      color: rgba(25, 118, 210, 1)
  .footer-info

    position: fixed
    left: 0
    bottom: 0
    width: 100%
    background-color: red
    color: white
    text-align: center
</style>
