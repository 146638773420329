<template>

  <v-card
    class="mx-auto pa-2 ma-2"
    max-width="95%"
    border
    rounded
    elevation="12"
    color="brown-lighten-5"
    style="border: 20px black"
  >
    <v-card-text>
      <div>


        <div class="d-flex align-center justify-center fill-height">
        <qrcode-vue :value="value" :size="size" level="H" />
        </div>


        <p class="text-subtitle-2 text-black  text-center" > <b > #{{ items.order_id }}</b></p>

      </div>

<b>Delivery </b>
        <v-btn prepend-icon="mdi-map-marker-outline" variant="text" color="green-darken-4">
        {{items.area}},{{items.city}}
        </v-btn>
      <div style="height: 10px"></div>

      <b>Delivery Date </b>
        <v-btn prepend-icon="mdi-calendar-range" variant="text" color="green-darken-4">


          {{datetime}}



        </v-btn>
      <div style="height: 10px"></div>

      <div class="text-medium-emphasis">
       <b>Is Subscription </b> <v-btn prepend-icon="mdi-calendar-sync-outline" variant="text"
                               color="green-darken-4">
        {{items.subsciption}}</v-btn>

        <div style="height: 10px"></div>
       <b>Phone </b>
        <v-btn prepend-icon="mdi-phone-forward-outline" variant="text" color="green-darken-4">
          {{items.phone}}</v-btn>
        <div style="height: 10px"></div>

        <div  v-if="items.status === `cancelled` " style="position: absolute; left: 33%; opacity: 0.3" >
          <v-icon
            color="error"
            icon="mdi-alert"
            size="128"
          ></v-icon>
        </div>

      <b>HUB </b>
        <v-btn prepend-icon="mdi-hub-outline" variant="text" color="green-darken-4">
          {{items.hub}}
        </v-btn>

        <div style="height: 10px"></div>

      <b>Delivery Day</b>  <v-btn  prepend-icon="mdi-calendar-week-begin" variant="text"
                            color="green-darken-4">
        {{items.day}}
      </v-btn>

        <div style="height: 10px"></div>

      <b>Address </b>
        <v-btn  prepend-icon="mdi-map-marker-outline" variant="text" color="green-darken-4">
          {{items.address}}
        </v-btn>
        <div style="height: 10px"></div>
       <b>Status </b>



        <v-btn
          v-if="items.status"
          prepend-icon="mdi-moped-outline"  color="red" variant="outlined" >
          {{ items.status }}
        </v-btn>



        <b>

       </b>

      </div>
    </v-card-text>

    <v-card-actions>

      <v-btn
        color="success" variant="tonal"
        prepend-icon="mdi-semantic-web"
        elevation="4"
        @click="getiyerms"
        :loading="loading"
      >View Details</v-btn>



      <v-btn
        v-if="items.status !== `cancelled` "
        variant="tonal"
        color="red"
        text="Cancel Order"
        @click="cancelModal"
        :loading="loading"
      ></v-btn>



    </v-card-actions>
  </v-card>



  <v-dialog max-width="800"  v-model="modalcancel">

    <template v-slot:default>
      <v-card title="Cancel your order" :loading="loading" danger color="danger">
        <template v-slot:text>
          Go Forward to cancel your order. You can reorder any time



        </template>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red"

            @click="cancelorder"
          >Cancel Order</v-btn>


          <v-btn
            color="danger"
            text="Close"
            variant="flat"
            @click="modalcancel = false"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>



  <v-dialog max-width="800"  v-model="modal">

    <template v-slot:default>
      <v-card :title="items.order_id" :loading="loading">
        <template v-slot:text>

          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                Item
              </th>
              <th class="text-left">
                Qty
              </th>
              <th class="text-left">
                Size
              </th>
              <th class="text-left">
                Rate
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in products"
              :key="item.name"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.qty }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.rate }}</td>

            </tr>
            </tbody>
          </v-table>

        </template>

        <v-card-actions>
          <v-spacer></v-spacer>



          <v-btn
            color="surface-variant"
            text="Close"
            variant="flat"
            @click="modal = false"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>



  </template>






<script>

import moment from "moment";
import cart from "@/stores/cart";
import QrcodeVue from 'qrcode.vue'
import item from "ol-ext/legend/Item";
import axios from "axios";
import URLLIST from "@/stores/url";

export default {
  components: {
    QrcodeVue,
  },
  props: ['items'],
  data() {
    return {
      datetime: null,
      modal: false,
      loading: false,
      modalcancel: false,
      products: []
    }
  },


    methods: {

    cancelModal(){
      this.modalcancel = true;

    },


      async cancelorder() {

        this.loading = true

        try {
          let authkey = localStorage.getItem('authkey')
          let createode = await axios.post(URLLIST.MY_ORDER_CANCEL, {
            order_id: this.items.order_id,
            authkey:authkey,
          });
          this.products = await createode.data
          console.log(createode);
          window.location.reload();
        } catch (e) {
          console.log(e)
        }

        this.loading = false


        // MY_ORDER_ITEM
      },


    async  getFormattedDate() {
      let data = await this.items.serverdeldate;


      let uhgjjf =  moment(data).format("DD-MM-YYYY");




        let joiuiy = uhgjjf

        this.datetime = joiuiy

      },

      async getiyerms() {

      this.loading = true
      this.modal = true
       try {
         let authkey = localStorage.getItem('authkey')
         let createode = await axios.post(URLLIST.MY_ORDER_ITEM, {
           order_id: this.items.order_id,
           authkey:authkey,
         });
         this.products = await createode.data
         console.log(createode);
       } catch (e) {
         console.log(e)
       }

      this.loading = false


      // MY_ORDER_ITEM
      }

    },

    created() {
      this.getFormattedDate()
      //   console.log(this.items.timestamp)
    }


}
</script>
