<template>
  <v-container class="fill-height">
    <div class="d-flex flex-wrap align-self-center align-center">

      <Slider/>

      <div class="pa-1 align-self-center align-center" style="width: 100%" v-if="prodload">

        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-skeleton-loader
                class="mx-auto border"
                max-width="100%"
                type="card-avatar, actions"
              ></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="12">
              <v-skeleton-loader
                class="mx-auto border"
                max-width="100%"
                type="card-avatar, actions"
              ></v-skeleton-loader>
            </v-col>


            <v-col cols="12" md="12">
              <v-skeleton-loader
                class="mx-auto border"
                max-width="100%"
                type="card-avatar, actions"
              ></v-skeleton-loader>
            </v-col>


          </v-row>
        </v-container>


      </div>

      <Category/>


      <div
        v-for="product in products.state.products"
        :key="product"
        class=" pa-1 align-self-center align-center"
      >
     <Product :productdata="product"/>

      </div>
    </div>



  </v-container>
</template>

<script >
import { store } from '../stores/store'
import products  from '../stores/products';
import axios from "axios";
import URLLIST from "@/stores/url";
import location from "@/stores/location";
import Slider from "@/components/Slider.vue";

export default {
  components: {Slider},

  data() {
    return {
      category: [],
    }
  },

  methods: {




   async checklocation() {
      let pincode =  localStorage.getItem('pincode')
      console.log(pincode)
      return pincode

    },

    async Setlocalitemstostate(){
      store.pincode =  localStorage.getItem('pincode');
      store.hub =  localStorage.getItem('hub');
      store.deliveryarea =  JSON.parse(localStorage.getItem('deliveryarea'));
      await this.GetProducts(store.hub);

    },

    async GetProducts(hub){

    },



    async setPincode() {
      if (await this.checklocation() === null){

        store.locationmodeal = true
        console.log('this.checklocation()') // => 2
      } else {

        store.locationmodeal = false
        await this.Setlocalitemstostate()
      }
    },

    async getprod() {
      let prod =  products.commit("productfetch");
      console.log("prod");
      console.log(products.state.products);
      location.commit('GetPincodestate');

      console.log(location.state)

    }
  },

  watch:{
    location(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    },
    checkpinnnnn(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    },
    checkhub(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
      products.commit("productfetch");
    }

  },

  computed: {
    products() {
      return products
    },
    location() {
      return location
    },
    prodload() {
      return products.state.prodload
    },
    checkpinnnnn () {
      return store.pincode
    },
    checkhub () {
      return products.state.hub
    }
  },



  created() {

    this.setPincode();
    this.getprod();
    location.commit('GetPincodestate');


  },

  mutations: {

    update(state, product) {

    },
  },


}
</script>
