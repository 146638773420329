<template>

  <v-card
    class=" "


  >
    <v-card-item>

      <v-img
        class="mx-auto"
        height="180"
        lazy-src="@/assets/images/placeholder_image.jpg"
        max-width="95%"
        :src="productdata.image"
      >
        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height">
            <v-progress-circular
              color="grey-lighten-4"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-img>
      <v-card-title class="text-center">
       {{productdata.caregoty}}
      </v-card-title>

      <v-card-subtitle class="text-md-center text-center">
        {{productdata.code}}

      </v-card-subtitle>

      <v-card-subtitle class="text-md-center text-center">
        <v-btn
          style=""
          color="cyan-darken-4"
          variant="outlined">
          {{ productdata.size }}
        </v-btn>
        <v-btn
          style="margin-left: 3px;"
          color="light-blue-darken-3"
          variant="outlined">
          {{ productdata.color }}
        </v-btn>

        <v-btn
          style="margin-left: 3px;"
          color="red-lighten-1"
          variant="outlined">
          {{ productdata.unit }} {{ productdata.uom }}
        </v-btn>

      </v-card-subtitle>

      <v-card-text class="bg-surface-light">
        {{productdata.name}} <br>
      HUB:   <b>{{productdata.fullfilment_center}}</b> <br>
      Next free Delivery in <b>{{ pincode }}</b>  is on <b>{{day }}</b>
        <p class="text-center" style="font-size: 10px; text-transform: uppercase; color: #2E7D32">
         <b> Pickup from our <b>{{productdata.fullfilment_center}}</b> Hub 24x7</b>
        </p>
      </v-card-text>

      <v-card-text class=""  style="background-color: rgba(0,201,10,0.06);
      border-width: 1px;margin-top: 3px;
       border-color: #00c90a!important;">
      <p style="text-transform: uppercase" class="text-center"><b> {{products.state.offers[1].text}}</b></p>

      </v-card-text>

      <v-card-text class="ma-1 pa-1 text-center  d-flex align-center "
                   style="">


        <p class="green text-h5" style="padding-left: 0px;color: #2E7D32">
          <b> ₹ {{ productdata.rate }}</b></p>

        <p class="text-subtitle-1"
           style="padding-left: 0px;  text-decoration: line-through;">  ₹ {{ productdata.ori_mrp }}</p>

        <p class="text-h5"
           style="padding-left: 10px;">


          <v-btn
            style="padding-left: 0px;"
            color="red-lighten-1"
             variant="outlined">{{ productdata.unit }} {{ productdata.uom }}
          </v-btn>
        </p>



      </v-card-text>





    </v-card-item>



    <div class="d-flex justify-center">
      <div
        class=""
      >
        <v-card-text>
          <v-text-field
            type="number"
            :rules="[rules.required, rules.counter]"
            v-model="qty"
            label="Quantity"></v-text-field>



        </v-card-text>
      </div>
      <div

        class="ma-2 pa-2 text-center text-h6 d-flex align-center
"
      >  <v-btn @click="addtocart(productdata)"
                color="success"
                block prepend-icon="mdi-cart" >
        Add to Cart
      </v-btn>



      </div>
    </div>


  </v-card>

  <v-snackbar
    v-model="snack"
    :timeout="2000"
    color="success"
    elevation="24"
  >
   Item has been added.

    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
       to="/cart"
      >
        Go To Cart
      </v-btn>



    </template>
  </v-snackbar>

</template>
<script >

import cart from "../stores/cart";
import location from "../stores/location";
import products from "@/stores/products";

export default {
  props: ['productdata'],

  data () {
    return {
      title: 'Preliminary report',
      email: '',
      qty:1,
      snack: false,
      deliveryarea:null,
      pincode:null,
      hub:null,
      delivery_day: null,
      day: null,


      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
      },
    }
  },

  methods:{

    getproddel(){
      this.pincode = localStorage.getItem('pincode');
      this.delivery_day = localStorage.getItem('delivery_day');
      this.day = localStorage.getItem('day');

    },


    async addtocart(productdata){

      let payload = {
        productdata : productdata,
        qty : this.qty
      }

     // console.log(payload);

      cart.commit('cartincrement', {
        payload
      })

      this.snack = true;





    }



  },

  watch:{

    location(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    }

  },

  created() {

    this.getproddel();
    //console.log(location)
  },


  computed: {
    products() {
      return products
    },
    location() {
      return location
    },
  }
}
</script>


