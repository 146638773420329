<template>
  <v-skeleton-loader v-if="loading === true" width="100%" type="card"></v-skeleton-loader>

  <v-carousel
    v-if="loading === false"
    height="300"
    :continuous="false"
    :show-arrows="false"
    delimiter-icon="mdi-square"
    hide-delimiter-background

  >
    <v-carousel-item :key="slider.name" v-for="slider in slider"
      :src="slider.url"
                     width="100%"

       cover
    ></v-carousel-item>
  </v-carousel>


</template>


<script>
import axios from "axios";
import URLLIST from "@/stores/url";
import moment from "moment/moment";
import {supabase} from "@/stores/supabase";

export default {
  data() {
    return {
      slider: [],
      loading: true,
    }
  },
  created() {
    this.getSliders()
  },
  methods: {
 async   getSliders(){

   this.loading = true

   try {

     let createode = await  supabase
       .from('slider').select('*')
   //  let createode = await axios.get(URLLIST.SLIDER);
     this.slider = await createode.data
     console.log(createode.data)
   } catch (e) {

     console.log(e)
   }
   this.loading = false
 }
  },

  watch: {
    loading(){
    //  this.getSliders()
    }
  },
  computed: {
  },
}
</script>
