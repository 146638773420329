<template>
  <IncludesAll/>

<div>

   <div v-if="loading">
     <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>
      <v-skeleton-loader
       class="mx-auto"
       elevation="12"
       max-width="400"
       type="table-heading, list-item-two-line, table-tfoot"
     ></v-skeleton-loader>
     <br>


   </div>

  <MyOrdersList v-if="items !== []"
                style="margin-top: 10px" v-bind="item.length"
                v-for="item in this.items" :items="item"/>

<div  style="margin-top: 10px">
  <p

    class="text-h6 text-center">No Orders Found</p>
</div>
</div>
  <AppFooter />
</template>

<script lang="ts">

import axios from "axios";
import URLLIST from "../stores/url";
import AuthStores from "../stores/AuthStores";

export default {
  data() {
    return {
      items : [],
      loading: true,
    }
  },


  created() {
    AuthStores.commit('Checkiflogin');
      this.getordes();
  },

  methods:{
    async getordes(){

      this.loading = true
      let authkey = localStorage.getItem('authkey')

      try {
        console.log(this.userinfo)
        let createode = await axios.post(URLLIST.MY_ORDER, {
          authkey:authkey,
        });





        if (createode.data.message === "Workflow executed successfully") {
          console.log(createode.data);
          this.loading = false
        } else {

          this.items = createode.data;
          this.loading = false
        }

        this.loading = false


      } catch (error) {
        console.log(error)
        this.loading = false

      }

    }
  },

  computed: {
    userinfo() {
      return AuthStores.state
    }
  },


  watch:{

    userinfo(value) {
      console.log(`My store value for 'doneTodosCount' changed to ${value}`);
    },

  },



}
</script>
