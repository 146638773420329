import { createStore } from 'vuex'
import axios from "axios";
import URLLIST from "./url";
import {supabase} from "@/stores/supabase";

export default createStore({
  state: {
    products: [],
    hub:"GW_IND_MH_SLG",
    pincode:null,
    prodload: false,
    productscat:[],
    caturl:null,
    offers: [],
  },
  mutations: {
    async getoffers(state){

      try {
        //let profile = await axios.get(URLLIST.OFFERS); //offers
        let profile = await  supabase
          .from('offers').select('*')
        state.offers = profile.data;
      } catch (e) {
        console.log(e);

      }

    },
    async productfetch(state) {
      state.prodload = true
      try {

       // let getaddresspincode = await axios.post(URLLIST.GET_PRODUCT, {"center": state.hub});
       // let getaddresspincode = await axios.post(URLLIST.GET_PRODUCT, {"center": state.hub});

        let getaddresspincode = await  supabase
          .from('products').select() .eq('fullfilment_center', state.hub);


        let returnprod = await getaddresspincode.data

        state.products = await returnprod;
        state.prodload = false
        return state.products;

      } catch (error) {
        console.log(error);

      }
    },

    async productfetchcatattr(state,payload) {
      state.prodload = true
      console.log(payload)
      let data =  {
        "fullfilment_center": state.hub,
        "category": payload.slug,
        "size": payload.size
      }
      console.log(data)
      try {
      //  let getaddresspincode = await axios.post(URLLIST.PROD_BY_SIZE, data);

        let getaddresspincode = await  supabase
          .from('products').select()
          .match({
            fullfilment_center: data.fullfilment_center,
            caregoty: data.category,
            size: data.size })

        // .eq( "fullfilment_center", data.fullfilment_center)
        //  .eq( "category", data.category)
        //  .eq( "size", data.size)

       // console.log(getaddresspincode2)


        let returnprod = await getaddresspincode.data

        state.productscat = await returnprod;
        state.prodload = false
        return state.productscat;

      } catch (error) {
        console.log(error);

      }
    },

    async productfetchcat(state,payload) {
      state.prodload = true
      console.log(payload)
      try {
       /* let getaddresspincode = await axios.post(URLLIST.GET_PRODUCT_CAT, {
          "fullfilment_center": state.hub,
          "caregoty": payload,

        });*/


        let getaddresspincode = await  supabase
          .from('products').select()
          .match({
            fullfilment_center: state.hub,
            caregoty: payload,
           // size: data.size
          })


        let returnprod = await getaddresspincode.data

        state.productscat = await returnprod;
        state.prodload = false
        return state.productscat;

      } catch (error) {
        console.log(error);

      }
    }

  },

  actions: {

  },
  getters: {

  }
})
