let BASE_URL = 'https://api_v2.letsgreenwave.com/webhook';
const URLLIST = {

  FETCH_PPINCODE : BASE_URL +  '/0ac56d2a-114c-4b49-a431-b97956cb3585',
  GET_PRODUCT : BASE_URL +  '/50ab30fa-0bfa-4d6e-a0bc-2b60f85d84c1',
  GET_PRODUCT_CAT : BASE_URL +  '/8af880a5-b29c-4a8b-86ed-5c154dfd922d',
  ORDER_API : BASE_URL +  '/5d390d60-006e-4715-934e-ef9ccccc3d3d',
  LOGIN_API : BASE_URL +  '/1321a374-d2cc-44b0-8f6d-7d4106207ce9',
  OTP_API : BASE_URL +  '/8400df3f-f909-403b-b4a3-13e207d61fde',
  PROFILE_API : BASE_URL +  '/8db8f546-e607-480f-b4f3-bb7786f9f070',
  REGISTER_API : BASE_URL +  '/cb5ebdb4-195d-40f8-b244-04eb0bf79a0a',
  CATEGORY : BASE_URL +  '/6eb92fe2-b6b6-4c14-94d4-90b32eb2265d',
  MY_ORDER : BASE_URL +  '/80c1e7a3-0e30-455f-b6d1-ed669a4ed9fe',
  MY_ORDER_ITEM : BASE_URL +  '/84ec2158-bad9-41a3-9cce-7534004f1797',
  MY_ORDER_CANCEL : BASE_URL +  '/71903ee8-8454-42a0-a55e-4e8033c925ad',
  MY_SUBS_CANCEL : BASE_URL +  '/d971e8d9-c79a-46c6-8144-1bca7536f5ad',
  MY_ORDER_SUBS : BASE_URL +  '/de766020-8284-470c-bcde-2773ad652cc8',
  ATTR_COLOR : BASE_URL +  '/9934b0a9-cc8a-4649-b162-637da318f2f9',
  ATTR_GET : BASE_URL +  '/9934b0a9-cc8a-4649-b162-637da318f2f9',
  SLIDER : BASE_URL +  '/17e86b1d-531f-4ad2-bd13-480e7978aa2c',
  OFFERS : BASE_URL +  '/a363e3d8-390d-4b0b-ba4a-7ff47051bf0b',
  PROD_BY_SIZE : BASE_URL +  '/7e6a57d5-86fa-4123-85eb-b143f2d0709c',
  UPCOMING_CITY : BASE_URL +  '/30b5b76f-e5d7-4257-a39e-026df0835485',
  OPERATIONAL_CITY : BASE_URL +  '/ca9469be-4f13-4ec3-94be-346eadbea989',
  PLACEHOLDER_IMAGE : '@/assets/images/placeholder_image.jpg',
  //PLACEHOLDER_IMAGE : '@/assets/images/placeholder_image.jpg',
}
export default URLLIST;
