<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import products from "./stores/products";

export default {

  methods:{

  },
  created() {
    products.commit("getoffers");

  },

}

</script>
